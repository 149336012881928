import { FC } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useQuestionsStore } from '@/stores';
import { Routes } from '@/constants';
import { ArrowLeft } from '@/components/common';

export const PreviousPageLinkUpdated: FC = () => {
  const { push } = useRouter();
  const { filterParams } = useQuestionsStore();

  const handleBack = () => {
    // @ts-ignore
    push({ pathname: Routes.Questions, query: filterParams });
  };

  return (
    <Flex borderRadius="8px" border="1px solid" borderColor="gray.300">
      <Button variant="wrapper" lineHeight="24px" leftIcon={<ArrowLeft />} onClick={handleBack}>
        All questions
      </Button>
      {/*<Button borderRadius="none" variant="wrapper">*/}
      {/*  <ChevronLeft />*/}
      {/*</Button>*/}
      {/*<Button borderLeftRadius="none" variant="wrapper">*/}
      {/*  <ChevronRight />*/}
      {/*</Button>*/}
    </Flex>
  );
};
