import React, { FC } from 'react';
import {
  Button,
  Center,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { useUser } from '@/hooks';
import { SignInButton } from '@clerk/nextjs';
import { handleSaveRedirectUrl } from '@/utils/handleSaveRedirectUrl';
import { useRouter } from 'next/router';

interface LoginModalProps {
  isModalOpen: boolean;
  onClose: () => void;
}

export const LoginModal: FC<LoginModalProps> = ({ isModalOpen = false, onClose }) => {
  const { isLoading } = useUser();
  const { asPath } = useRouter();

  return (
    <Modal
      isCentered
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
      onClose={onClose}
      isOpen={isModalOpen}
      motionPreset="scale"
    >
      <ModalOverlay />
      <ModalContent minH="220px" bg="gray.100">
        <ModalCloseButton disabled={isLoading} />
        <ModalBody display="flex" justifyContent="center" alignItems="center">
          <Center flexDirection="column">
            <Heading as="h3" mb="30px" textAlign="center" fontWeight="500" fontSize="18px" color="black.300">
              Sign In before performing this action
            </Heading>
            <Flex alignItems="center">
              <SignInButton>
                <Button
                  maxH="32px"
                  autoFocus
                  isLoading={isLoading}
                  colorScheme="red"
                  onClick={() => handleSaveRedirectUrl(asPath)}
                >
                  Sign In
                </Button>
              </SignInButton>
            </Flex>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
