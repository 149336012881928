import { FC, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import Image from 'next/image';
import { useRouter } from 'next/router';
import {
  Box,
  Button,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  useMediaQuery,
  useOutsideClick,
} from '@chakra-ui/react';
import { useEffectOnce, useUser } from '@/hooks';
import { getUserName } from '@/utils';
import { Routes } from '@/constants';
import { AppLink, Avatar, ShareIcon, ShareModal } from '@/components/common';
import { SignInButton, SignOutButton } from '@clerk/nextjs';
import { handleSaveRedirectUrl } from '@/utils/handleSaveRedirectUrl';
import { Question as BaseQuestion } from '@/types/models';
import { PreviousPageLinkUpdated } from '@/components/pages/question';
import { BurgerMenu, MenuIcon } from '.';

interface HeaderProps extends Partial<Pick<BaseQuestion, 'company' | 'category'>> {
  isGeneralAssemblyMvp?: boolean;
  isQuestionPage?: boolean;
}

export interface HeaderNavItem {
  title: string;
  path: string;
}

const NAV_ITEMS: HeaderNavItem[] = [
  { title: 'Practice Interview Questions', path: Routes.Questions },
  { title: 'Learn SQL', path: Routes.SQLTutorial },
  { title: 'Get 1:1 Coaching', path: Routes.Pricing },
];

const Header: FC<HeaderProps> = (props) => {
  const { isGeneralAssemblyMvp, isQuestionPage, company, category } = props;

  const { isOpen: isMenuOpen, onToggle } = useDisclosure();
  const { isOpen: isShareModalOpen, onToggle: onShareModalToggle } = useDisclosure();
  const [isLargerThan980] = useMediaQuery(`(min-width: 890px)`, {
    ssr: true,
    fallback: false,
  });
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [isOutsideClick, setIsOsOutsideClick] = useState<boolean>(false);
  const { asPath } = useRouter();

  const { user, isAuthInitialized, isLoading, logout, login } = useUser();
  const userName = user ? getUserName(user) : '';

  const AUTHORIZED_NAV_ITEMS = user ? NAV_ITEMS : NAV_ITEMS.filter(({ path }) => path !== Routes.Pricing);

  const handleLogout = () => {
    logout();
  };

  useOutsideClick({
    enabled: isMenuOpen,
    ref: menuRef,
    handler: () => {
      setIsOsOutsideClick(true);
      onToggle();
    },
  });

  useEffect(() => {
    if (isMenuOpen && menuRef.current) {
      disableBodyScroll(menuRef.current);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (isMenuOpen) {
      onToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  useEffectOnce(() => {
    localStorage.removeItem('pathnameOnSignIn');

    if (user) {
      return;
    }

    login();

    return () => {
      if (isMenuOpen) {
        onToggle();
      }
    };
  });

  const handleEsc = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape' && isMenuOpen) {
        onToggle();
      }
    },
    [isMenuOpen, onToggle],
  );

  const handleMenuIconClick = useCallback(() => {
    if (isOutsideClick) return setIsOsOutsideClick(false);

    onToggle();
  }, [isOutsideClick, onToggle]);

  return (
    <Box zIndex={10} h="56px" position="fixed" top={0} right={0} left={0} bg="gray.100" px="4">
      <ShareModal isModalOpen={isShareModalOpen} onClose={onShareModalToggle} company={company} category={category} />

      <Flex
        position="relative"
        h="14"
        gap="30px"
        alignItems="center"
        justifyContent="space-between"
        onKeyDown={handleEsc}
        zIndex={100}
      >
        <Center gap="29px" flexShrink={0}>
          <AppLink href={Routes.Home} display="flex">
            {isGeneralAssemblyMvp ? (
              <Image src="/general_assembly_logo.png" width={290} height={32} alt="logo" />
            ) : (
              <Image src="/Logo.svg" width={161} height={32} alt="logo" />
            )}
          </AppLink>

          {isLargerThan980 && !isQuestionPage && (
            <Flex gap="30px">
              {AUTHORIZED_NAV_ITEMS.map(({ title, path }) => (
                <AppLink key={title} href={path} color="black.200" fontSize="18px">
                  {title}
                </AppLink>
              ))}
            </Flex>
          )}
          {isLargerThan980 && isQuestionPage && <PreviousPageLinkUpdated />}
        </Center>

        <Flex gap="8px" alignItems="center">
          {isQuestionPage && isLargerThan980 && (
            <Button variant="wrapper" maxH="32px" leftIcon={<ShareIcon />} onClick={onShareModalToggle}>
              Share
            </Button>
          )}

          {isLargerThan980 && isAuthInitialized && user && (
            <Flex alignItems="center">
              <Text mr={3} textAlign="right" wordBreak="break-word">
                {userName}
              </Text>
              <Menu>
                <MenuButton
                  as={Button}
                  rounded="full"
                  variant="link"
                  cursor="pointer"
                  minW="0"
                  _hover={{ textDecoration: 'none' }}
                >
                  <Avatar size="sm" avatarId={user.avatar} userName={userName} />
                </MenuButton>
                <MenuList>
                  <MenuItem>
                    <AppLink href={Routes.ProfilePerformance} w="100%" _hover={{ textDecor: 'none' }}>
                      Your Performance
                    </AppLink>
                  </MenuItem>
                  <MenuItem>
                    <AppLink href={Routes.AccountSettings} w="100%" _hover={{ textDecor: 'none' }}>
                      Account Settings
                    </AppLink>
                  </MenuItem>
                  <MenuItem>
                    <AppLink href={Routes.Premium} w="100%" _hover={{ textDecor: 'none' }}>
                      Premium
                    </AppLink>
                  </MenuItem>
                  <SignOutButton redirectUrl={asPath}>
                    <MenuItem onClick={!isLoading ? handleLogout : undefined}>Log out</MenuItem>
                  </SignOutButton>
                </MenuList>
              </Menu>
            </Flex>
          )}

          {isLargerThan980 && isAuthInitialized && !user && (
            <Flex alignItems="center">
              <SignInButton>
                <Button
                  maxH="32px"
                  colorScheme="red"
                  isDisabled={isLoading}
                  onClick={() => handleSaveRedirectUrl(asPath)}
                >
                  Sign In
                </Button>
              </SignInButton>
            </Flex>
          )}
        </Flex>

        {!isLargerThan980 && <MenuIcon isOpen={isMenuOpen} handleClick={handleMenuIconClick} />}
      </Flex>

      <BurgerMenu menuItems={AUTHORIZED_NAV_ITEMS} isOpen={isMenuOpen} forwardedRef={menuRef} />
    </Box>
  );
};

export default Header;
