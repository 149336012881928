import React, {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import dynamic from 'next/dynamic';
import { Ace } from 'ace-builds';
import { Flex, Text } from '@chakra-ui/react';
import { CodeEditorControls, CompanyInfo, PythonQueryTable, RunResult } from '@/components/pages/question';
import { Loader } from '@/components/common';
import { ExpectedPythonOutput, SubmitQueryResult } from '@/types/models';
import { ActiveCampaignService, Logger, SubmitQueryService } from '@/services';
import { GeneralAssemblyQuestionTab, MixpanelEvent, QuestionStatus, StatusCode, Tab } from '@/constants';
import { Operations } from '@/constants/pages';
import { Question, Submission } from '@/types/pages/question';
import { debounce } from '@/utils';
import { LoadingState } from '@/types';
import { useRunQueryStore } from '@/stores';
import { MPTracker } from '@/services/analytics';

const PYTHON_TIMEOUT_ERROR_DELAY = 5 * 1000;
const PYTHON_FUNC_NAME_ERROR = 'NameError: function name is invalid';
const PYTHON_TIMEOUT_ERROR =
  'Timeout error: This code is taking too long to execute. Please correct your entry and try again.';

type CutQuestionType = Pick<
  Question,
  | 'pythonFunctionQuery'
  | 'pythonTestCases'
  | 'category'
  | 'difficulty'
  | 'company'
  | 'slug'
  | 'accessGroups'
  | 'companyInfo'
>;

interface CodeEditorTabProps extends CutQuestionType {
  questionId: number;
  baseQuery: string;
  codeEditorClassName: string;
  setSubmitResult: (newVal: SubmitQueryResult | null) => void;
  currentOperation: Operations;
  setCurrentOperation: Dispatch<SetStateAction<Operations>>;
  setTabIndex: (tabIndex: Tab) => void;
  setIsSubmitTableLoading: (isSubmitTableLoading: boolean) => void;
  setSubmitTableError: (error: string | null) => void;
  setSubmissionsState: Dispatch<SetStateAction<LoadingState<Submission[]>>>;
  isMobile: boolean;
  isGeneralAssemblyMvp?: boolean;
  isPythonQuestion?: boolean;
}

const DynamicCodeEditor = dynamic(() => import('@/components/pages/question/CodeEditor'), {
  ssr: false,
  loading: () => <Loader height="500px" />,
});

const CodeEditorTab: FC<CodeEditorTabProps> = (props) => {
  const {
    questionId,
    baseQuery,
    currentOperation,
    codeEditorClassName,
    setSubmitResult,
    setCurrentOperation,
    setTabIndex,
    setIsSubmitTableLoading,
    setSubmitTableError,
    setSubmissionsState,
    isMobile,
    isGeneralAssemblyMvp,
    isPythonQuestion,
    pythonFunctionQuery,
    pythonTestCases,
    category,
    difficulty,
    company,
    accessGroups,
    companyInfo,
  } = props;

  const {
    pythonExpectedCasesExecuted,
    setPythonExpectedCasesExecuted,
    tableResult,
    setResultTable: setRunResult,
    expectedPythonOutput,
    setItemToExpectedPythonOutput,
    resetExpectedPythonOutput,
    isRunOutputTableVisible,
    setIsRunOutputTableVisible,
    isOutputTableLoading,
    setIsOutputTableLoading,
    outputTableError,
    setOutputTableError,
  } = useRunQueryStore();

  const mixPanelData = useMemo(
    () => ({
      category,
      difficulty,
      company,
      accessGroups: accessGroups?.join(' ,'),
    }),
    [accessGroups, category, company, difficulty],
  );

  const pythonWorker: MutableRefObject<Worker | null> = useRef<Worker>(null);
  const pythonRunnerTimer: MutableRefObject<NodeJS.Timeout | undefined> = useRef();

  const [editorValue, setEditorValue] = useState<string>(baseQuery);
  const editorValueCacheKey = `codeInput-${questionId}`;

  const [isPythonExecuting, setIsPythonExecuting] = useState(false);
  const [isPythonRunnerReady, setIsPythonRunnerReady] = useState(false);
  const [pythonExecError, setPythonExecError] = useState<null | string>(null);
  const [errorLineNumbers, setErrorLineNumbers] = useState<Ace.Annotation[]>([]);

  const isPythonCasesTabVisible =
    Boolean(expectedPythonOutput.length) && !pythonExecError && isPythonQuestion && isRunOutputTableVisible;

  const isRunLoading = isPythonQuestion
    ? !isPythonRunnerReady || isPythonExecuting
    : currentOperation === Operations.Run;
  const isSubmitLoading = isPythonQuestion
    ? !isPythonRunnerReady || currentOperation === Operations.Submit || isPythonExecuting
    : currentOperation === Operations.Submit;

  useEffect(() => {
    if (!isPythonQuestion || pythonWorker.current || isPythonRunnerReady) {
      return;
    }
    pythonWorker.current = new Worker(new URL('./pyWorker.ts', import.meta.url));

    if (pythonRunnerTimer.current) {
      clearTimeout(pythonRunnerTimer.current);
    }

    pythonWorker.current.onmessage = ({
      data,
    }: MessageEvent<
      {
        isPythonRunnerReady?: boolean;
        error?: { message: string; annotations: Ace.Annotation[] };
      } & ExpectedPythonOutput
    >) => {
      if (data.isPythonRunnerReady) {
        return setIsPythonRunnerReady(true);
      }

      if (data.error) {
        setPythonExecError(data.error.message);
        setErrorLineNumbers(data.error.annotations || []);
      }

      setItemToExpectedPythonOutput(data);
      setCurrentOperation((prevState) => {
        if (prevState === Operations.Run) {
          setIsRunOutputTableVisible(true);
          setCurrentOperation(Operations.Watch);
        }
        return prevState;
      });

      setIsPythonExecuting(false);
      clearTimeout(pythonRunnerTimer.current);
    };
  }, [
    currentOperation,
    isPythonQuestion,
    isPythonRunnerReady,
    setCurrentOperation,
    setIsPythonRunnerReady,
    setIsRunOutputTableVisible,
    setItemToExpectedPythonOutput,
  ]);

  useEffect(() => {
    return () => {
      setPythonExpectedCasesExecuted(false);
    };
  }, [setPythonExpectedCasesExecuted]);

  useEffect(() => {
    if (!isPythonQuestion || pythonExpectedCasesExecuted || !pythonTestCases) {
      return;
    }

    if (!isPythonRunnerReady) {
      return;
    }

    setPythonExpectedCasesExecuted(true);
    resetExpectedPythonOutput();
    pythonTestCases.map(async (testQuery, index) => {
      try {
        const runPythonTestCaseQuery = `${testQuery.funcName}(${testQuery.args.map(
          ({ name, value }) => `${name}=${value}`,
        )})`;
        const testCaseQuery = `${pythonFunctionQuery}${runPythonTestCaseQuery}`;

        pythonWorker.current?.postMessage({ pythonQuery: testCaseQuery, index: index + 1, testQuery });
      } catch (error: any) {
        setPythonExecError(error.type);
      }
    });
  }, [
    isPythonQuestion,
    isPythonRunnerReady,
    pythonExpectedCasesExecuted,
    expectedPythonOutput,
    setItemToExpectedPythonOutput,
    pythonTestCases,
    pythonFunctionQuery,
    resetExpectedPythonOutput,
    setPythonExpectedCasesExecuted,
  ]);

  useEffect(() => {
    if (!isPythonQuestion || currentOperation !== Operations.Submit || isPythonExecuting) {
      return;
    }

    if (expectedPythonOutput.length !== pythonTestCases?.length) {
      return;
    }
    const isAllCasesSolved = !Boolean(expectedPythonOutput.filter((value) => !value?.isValid).length);
    const isOneTestPassed = expectedPythonOutput.find((value) => value?.isValid);
    (async () => {
      try {
        const status = isAllCasesSolved
          ? QuestionStatus.Solved
          : isOneTestPassed
          ? QuestionStatus.Mismatched
          : QuestionStatus.Wrong;
        const submission = await SubmitQueryService.submitPythonQuery(questionId, editorValue, status);

        setSubmitResult({
          submission,
          status: submission.status,
          payload: { adminQueryResult: [], userQueryResult: [], isPythonQuestion, pythonCases: expectedPythonOutput },
        });
        setSubmitTableError(null);
        setSubmissionsState((prevState) => ({
          ...prevState,
          data: [submission, ...prevState.data],
        }));
      } catch (e) {
        Logger.error(e);
      } finally {
        setCurrentOperation(Operations.DisabledAll);
        setIsSubmitTableLoading(false);
      }
    })();
  }, [
    currentOperation,
    editorValue,
    expectedPythonOutput,
    isPythonExecuting,
    isPythonQuestion,
    pythonTestCases?.length,
    questionId,
    setCurrentOperation,
    setIsSubmitTableLoading,
    setSubmissionsState,
    setSubmitResult,
    setSubmitTableError,
  ]);

  const runPythonCode = useCallback(
    (isSubmitTriggeredFunction: boolean = false) => {
      const pyFuncName = expectedPythonOutput[0]?.testQuery.funcName;

      if (!editorValue.includes(pyFuncName)) {
        setPythonExecError(PYTHON_FUNC_NAME_ERROR);
        setErrorLineNumbers([{ row: 0, type: 'error', text: PYTHON_FUNC_NAME_ERROR }]);
      }

      if (pythonExecError) {
        setPythonExecError(null);
        setErrorLineNumbers([]);
      }

      if (!isSubmitTriggeredFunction) {
        setIsRunOutputTableVisible(false);
        setCurrentOperation(Operations.Run);
      }

      resetExpectedPythonOutput();
      setIsPythonExecuting(true);

      expectedPythonOutput.map(async ({ expectedOutput, testQuery: { funcName, args }, index }) => {
        const runPythonTestCaseQuery = `${funcName}(${args.map(({ name, value }) => `${name}=${value}`)})`;
        const testCaseQuery = `${editorValue}
        
${runPythonTestCaseQuery}`;

        const initialOutput = {
          index,
          testQuery: { funcName, args },
          expectedOutput,
        };

        try {
          pythonWorker.current?.postMessage({ pythonQuery: testCaseQuery, ...initialOutput });
        } catch (error: any) {
          Logger.error(error);

          return {
            ...initialOutput,
            isValid: false,
          };
        }
      });

      pythonRunnerTimer.current = setTimeout(() => {
        resetExpectedPythonOutput();
        pythonWorker.current?.terminate();
        pythonWorker.current = null;
        setIsPythonRunnerReady(false);
        setIsPythonExecuting(false);
        setPythonExpectedCasesExecuted(false);
        setIsSubmitTableLoading(false);
        setPythonExecError(PYTHON_TIMEOUT_ERROR);
        setSubmitTableError(PYTHON_TIMEOUT_ERROR);
        setCurrentOperation(Operations.Watch);
      }, PYTHON_TIMEOUT_ERROR_DELAY);

      ActiveCampaignService.setLastEngagementDateOnRunPythonSolution();
    },

    [
      editorValue,
      expectedPythonOutput,
      pythonExecError,
      resetExpectedPythonOutput,
      setCurrentOperation,
      setIsRunOutputTableVisible,
      setIsSubmitTableLoading,
      setPythonExpectedCasesExecuted,
      setSubmitTableError,
    ],
  );

  const runTests = useCallback(async () => {
    if (currentOperation !== Operations.Watch) {
      return;
    }
    setCurrentOperation(Operations.Run);
    setIsOutputTableLoading(true);

    try {
      const outputResponse = await SubmitQueryService.runQuery(questionId, editorValue);

      setRunResult(outputResponse);
      setIsRunOutputTableVisible(true);
      setOutputTableError(null);
    } catch (error: any) {
      if (error.response?.status !== StatusCode.BadRequest) {
        Logger.error(error);
      }

      setIsRunOutputTableVisible(true);
      setOutputTableError(error.response?.data?.error || 'Something went wrong :/');
    } finally {
      setCurrentOperation(Operations.DisabledRun);
      setIsOutputTableLoading(false);
    }
  }, [
    currentOperation,
    setCurrentOperation,
    setIsOutputTableLoading,
    setIsRunOutputTableVisible,
    setOutputTableError,
    setRunResult,
    editorValue,
    questionId,
  ]);

  const hideTestResults = useCallback(() => {
    setIsRunOutputTableVisible(false);
    setCurrentOperation(Operations.Watch);
  }, [setCurrentOperation, setIsRunOutputTableVisible]);

  const preSubmitOperation = useCallback(() => {
    setCurrentOperation(Operations.Submit);
    // @ts-ignore
    setTabIndex(isGeneralAssemblyMvp ? GeneralAssemblyQuestionTab.QuestionSubmissions : Tab.QuestionSubmissions);
    setIsSubmitTableLoading(true);
  }, [isGeneralAssemblyMvp, setCurrentOperation, setIsSubmitTableLoading, setTabIndex]);

  const submitPythonQuery = useCallback(async () => {
    if (currentOperation === Operations.Submit) {
      return;
    }
    resetExpectedPythonOutput();
    setPythonExecError(null);
    preSubmitOperation();

    setIsRunOutputTableVisible(false);
    runPythonCode(true);
  }, [currentOperation, preSubmitOperation, resetExpectedPythonOutput, runPythonCode, setIsRunOutputTableVisible]);

  const submitQuery = useCallback(async () => {
    if (currentOperation === Operations.Submit) {
      return;
    }
    preSubmitOperation();

    try {
      const submitResponse = await SubmitQueryService.submitQuery(questionId, editorValue);

      setSubmitResult(submitResponse);
      setSubmitTableError(null);
      setSubmissionsState((prevState) => ({
        ...prevState,
        data: [submitResponse.submission, ...prevState.data],
      }));
    } catch (error: any) {
      if (error.response?.status !== StatusCode.BadRequest) {
        Logger.error(error);
      }

      setSubmitTableError(null);
      if (error.response?.data?.submission) {
        setSubmissionsState((prevState) => ({
          ...prevState,
          data: [error.response.data.submission, ...prevState.data],
        }));
      }
      if (error.response?.data?.payload) {
        setSubmitResult(error.response.data);
      } else {
        setSubmitTableError(error.response?.data?.error || 'Something went wrong :/');
      }
    } finally {
      setCurrentOperation(Operations.DisabledAll);
      setIsSubmitTableLoading(false);
    }
  }, [
    currentOperation,
    preSubmitOperation,
    questionId,
    editorValue,
    setSubmitResult,
    setSubmitTableError,
    setSubmissionsState,
    setCurrentOperation,
    setIsSubmitTableLoading,
  ]);

  const saveEditorValueToCache = useMemo(
    () =>
      debounce((value: string) => {
        localStorage.setItem(editorValueCacheKey, value);
      }, 1000),
    [editorValueCacheKey],
  );

  const changeEditorValue = useCallback(
    (newValue?: string) => {
      setEditorValue(newValue || '');
      saveEditorValueToCache(newValue || '');
      setCurrentOperation(Operations.Watch);
    },
    [saveEditorValueToCache, setCurrentOperation],
  );

  const runCodeHandler = useCallback(() => {
    MPTracker.track(MixpanelEvent.ProblemRun, mixPanelData);

    return isPythonQuestion ? runPythonCode() : runTests();
  }, [isPythonQuestion, mixPanelData, runPythonCode, runTests]);

  const submitQueryHandler = useCallback(() => {
    MPTracker.track(MixpanelEvent.ProblemSubmit, mixPanelData);

    return isPythonQuestion ? submitPythonQuery() : submitQuery();
  }, [isPythonQuestion, mixPanelData, submitPythonQuery, submitQuery]);

  useEffect(() => {
    const savedEditorInput = localStorage.getItem(editorValueCacheKey);
    if (savedEditorInput) {
      setEditorValue(savedEditorInput);
    }
  }, [editorValueCacheKey]);

  return (
    <Flex overflowY="auto" pb="16px" direction="column" className={codeEditorClassName}>
      <CompanyInfo companyInfo={companyInfo} difficulty={difficulty} />

      <DynamicCodeEditor
        value={editorValue}
        onChange={changeEditorValue}
        isPythonMode={isPythonQuestion}
        errorAnnotations={errorLineNumbers}
      />

      <CodeEditorControls
        onTestsRun={runCodeHandler}
        onSubmit={submitQueryHandler}
        isRunLoading={isRunLoading}
        isRunDisabled={!editorValue || currentOperation !== Operations.Watch}
        isSubmitLoading={isSubmitLoading}
        isSubmitDisabled={!editorValue}
        isMobile={isMobile}
        isPythonQuestion={isPythonQuestion}
      />
      {isRunOutputTableVisible && (
        <RunResult
          isLoading={isOutputTableLoading}
          result={tableResult}
          onChevronClick={hideTestResults}
          error={outputTableError}
        />
      )}
      {pythonExecError && <Text color="red">{pythonExecError}</Text>}
      {isPythonCasesTabVisible && (
        <PythonQueryTable pythonOutput={expectedPythonOutput} hideTestResults={hideTestResults} />
      )}
    </Flex>
  );
};

export default CodeEditorTab;
