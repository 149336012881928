import { Icon, IconProps } from '@chakra-ui/react';

export const ArrowLeft = (props: IconProps): JSX.Element => (
  <Icon viewBox="0 0 14 12" fill="none" {...props}>
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 11L1 6M1 6L6 1M1 6H13"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);
