import React, { FC } from 'react';
import { Flex, Image, Text } from '@chakra-ui/react';
import { getApiUrlPrefix } from '@/utils';
import { getDifficultyColor } from '@/components/pages/questions/QuestionRowItem/helpers';
import { Question } from '@/types/models';
import Link from 'next/link';
import { Routes } from '@/constants';
import * as querystring from 'query-string';

export const CompanyInfo: FC<Pick<Question, 'companyInfo' | 'difficulty'>> = ({ companyInfo, difficulty }) => (
  <Flex gap="28px" mb="18px">
    {companyInfo && (
      <Flex flexDir="column">
        <Text fontSize="12px" lineHeight="16px" fontWeight={500} color="black.300">
          Sourced from
        </Text>

        <Link href={`${Routes.Questions}?${querystring.stringify({ company: companyInfo.name })}`}>
          <Flex alignItems="center" gap="4px">
            <Image src={getApiUrlPrefix(companyInfo?.icon)} width="16px" height="16px" mt="2px" />

            <Text fontSize="16px" lineHeight="24px" fontWeight={600}>
              {companyInfo.name}
            </Text>
          </Flex>
        </Link>
      </Flex>
    )}

    <Flex flexDir="column">
      <Text fontSize="12px" lineHeight="16px" fontWeight={500} color="black.300">
        Difficulty
      </Text>

      <Link href={`${Routes.Questions}?${querystring.stringify({ difficulty })}`}>
        <Text fontSize="16px" lineHeight="24px" fontWeight={600} color={getDifficultyColor(difficulty)}>
          {difficulty}
        </Text>
      </Link>
    </Flex>
  </Flex>
);
